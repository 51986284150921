import { Button, PillButton } from "@components/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { AltAboutJk } from "@components/AboutJk"
import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { CursorMarker } from "@components/CursorMarker"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import ImpactfulCampaigns from "@svg/meaningful-messaging/impactful-campaigns.svg"
import LaunchAndSocialize from "@svg/meaningful-messaging/launch-and-socialize.svg"
import HeroShapeOne from "@svg/social-good/hero-shape-01.svg"
import Illustration from "@svg/social-good/illustration.svg"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import StoryCreation from "@svg/meaningful-messaging/story-creation.svg"
import { splitText } from "@utils"
import { graphql } from "gatsby"

const MeaningfulMessagingPage = ({ data }) => {
  const { aboutJkImage, heroImage, csAdImage, csMmImage, csSaniImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const contactSubmission = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "meaningful_messaging" })
  }

  return (
    <Layout>
      <Seo title="Meaningful Messaging" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "It’s time to take a fresh look at your B2B brand story.",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="text-white text-clamp-16-18">
                  <span className="font-bold text-teal-medium">
                    B2B companies with strong brands show a 74% higher return on their marketing investment.
                    <sup>
                      <a
                        href="https://www.bcg.com/publications/2021/why-brand-marketing-matters"
                        target="_blank"
                        rel="noreferrer"
                      >
                        1
                      </a>
                    </sup>
                  </span>
                </p>
                <p className="text-white text-clamp-16-18">
                  Strong brands don't stand still—they evolve in step with their customers' needs. We can help you tell
                  your most authentic and powerful brand story—one that reflects who you are today, resonates with your
                  audiences, and stands out from the competition.
                </p>
                <div>
                  <Button className="mt-8 text-purple-light" link="#meaningful-messaging-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative flex items-center col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative" data-aos="fade-left">
                        <div className="absolute inset-y-0 right-0 flex">
                          <div className="transform scale-110 translate-x-8 sm:translate-x-2 lg:translate-x-8 lg:translate-y-12">
                            <HeroShapeOne />
                          </div>
                        </div>

                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Meaningful Messaging" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section className="pb-clamp-16-18">
            <div className="container">
              <div data-aos="fade">
                <PillButton staticButton={true} className="bg-purple-lighter">
                  Our services
                </PillButton>
              </div>
              <div data-aos="fade-up" className="mt-8" style={{ maxWidth: "850px" }}>
                <p>
                  Together, we’ll create a refreshed brand story that conveys your distinctive identity, purpose, and
                  value in a meaningful and authentic way.
                </p>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up">
                    <div>
                      <StoryCreation />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">Story creation</h2>
                      <p className="mt-4 text-16">
                        Craft an emotionally driven, value-focused messaging framework tailored to your audiences that
                        goes beyond the “what” to convey why people should choose your company.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <LaunchAndSocialize />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">Launch &amp; socialization</h2>
                      <p className="mt-4 text-16">
                        Develop a comprehensive strategy to introduce your new brand story to the world—one that
                        captivates your customers, empowers your leaders, and inspires your employees.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 sm:flex-row" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <ImpactfulCampaigns />
                    </div>
                    <div>
                      <h2 className="font-bold text-20">Impactful campaigns</h2>
                      <p className="mt-4 text-16">
                        Launching your new brand story is just the beginning. Keep the momentum going with marketing
                        campaigns that reach your targets at the right times and places, driving meaningful interactions
                        and building long-term relationships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-10 xl:pb-16 md:pt-clamp-14-30 xl:pt-28 bg-teal-medium">
            <div className="container">
              <div className="grid-cols-12 gap-4 xl:grid">
                <div className="flex items-center col-span-4 col-start-3" data-aos="fade-right">
                  <div>
                    <h3 className="leading-normal text-clamp-35-45">Let’s talk about revitalizing your brand story.</h3>
                    <div>
                      <Button
                        className="mt-8 text-gray-darkest button-down"
                        down={true}
                        link="#meaningful-messaging-form"
                      >
                        Get in touch
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="relative flex justify-center col-span-5 mt-10 xl:mt-0 xl:block">
                  <div className="relative w-10/12 xl:ml-8 xl:w-160 z-1" data-aos="fade-left">
                    <Illustration />
                  </div>
                  <div className="absolute inset-x-0 bottom-0 xl:hidden">
                    <div className="aspect-w-667 aspect-h-93 extend before:bg-white-dark after:bg-white-dark"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Brand stories we’ve supercharged.",
                    }),
                  }}
                />
                <ClientList
                  className="gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "ad",
                    "adp",
                    "caterpillar",
                    "children",
                    "dupont",
                    "jj",
                    "leb",
                    "merck",
                    "netflix",
                    "stryker",
                  ]}
                />
              </div>
            </div>
          </section>

          <section className="bg-gray pt-clamp-12-14 pb-clamp-15-25">
            <div className="container">
              <div>
                <PillButton staticButton={true}>CASE STUDY SNAPSHOTS</PillButton>
                <div className="mt-12">
                  <h2
                    className="leading-none text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "See how we give brands a boost.",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="hidden grid-cols-3 gap-4 lg:grid mt-clamp-11-20">
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csAdImage)} alt="AD" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">AD</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Evolving a fragmented brand into a powerful platform.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      After four decades of expansion through mergers and acquisitions, AD’s brand had become
                      disjointed. JK cut through the clutter to help them tell a singular, powerful, value-focused story
                      across seven industries and three countries.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csMmImage)} alt="MANCHESTER MILLS" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">MANCHESTER MILLS</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Creating a compelling new story for a legacy brand.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      When their parent company reorganized, 90-year-old textiles brand Manchester Mills became a
                      separate entity. So we created a new brand story to convey their unique identity and voice, and
                      emotionally driven messaging tailored to their target customers.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csSaniImage)} alt="SANI PROFESSIONAL" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">SANI PROFESSIONAL</h3>
                    <h4 className="font-bold text-clamp-18-28 text-white-dark">
                      Crafting a new story for a transformed brand landscape.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      The pandemic created new demand for Sani’s disinfecting wipes—and new challenges for their
                      customers. JK evolved their messaging to reflect their deep understanding of customers’ current
                      concerns and put a spotlight on Sani’s superior product performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container lg:hidden mt-clamp-11-20">
              <div className="relative flex">
                <CursorMarker>
                  <CursorMarker.Gallery />
                </CursorMarker>
                <div className="w-full sm:w-5/6 lg:w-1/2">
                  <Carousel>
                    <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csAdImage)} alt="Logo 1" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">AD</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Evolving a fragmented brand into a powerful platform.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              After four decades of expansion through mergers and acquisitions, AD’s brand had become
                              disjointed. JK cut through the clutter to help them tell a singular, powerful,
                              value-focused story across seven industries and three countries.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csMmImage)} alt="Logo 2" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">MANCHESTER MILLS</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Creating a compelling new story for a legacy brand.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              When their parent company reorganized, 90-year-old textiles brand Manchester Mills became
                              a separate entity. So we created a new brand story to convey their unique identity and
                              voice, and emotionally driven messaging tailored to their target customers.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csSaniImage)} alt="Logo 3" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">SANI PROFESSIONAL</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Crafting a new story for a transformed brand landscape.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              The pandemic created new demand for Sani’s disinfecting wipes—and new challenges for their
                              customers. JK evolved their messaging to reflect their deep understanding of customers’
                              current concerns and put a spotlight on Sani’s superior product performance.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK blends empathy, insight, and a clear understanding of your goals to deliver communications platforms
                and programs that build the bonds that grow your business.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="meaningful-messaging-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-6">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-gray-darkest": "Ready to strengthen your story?",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Social Good"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default MeaningfulMessagingPage

export const MeaningfulMessagingPageQuery = graphql`
  query meaningfulMessagingPagePageQuery {
    heroImage: file(relativePath: { eq: "meaningful-messaging/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    goingBeyondImage: file(relativePath: { eq: "social-story/going-beyond.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csAdImage: file(relativePath: { eq: "meaningful-messaging/cs-ad.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csMmImage: file(relativePath: { eq: "meaningful-messaging/cs-mm.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csSaniImage: file(relativePath: { eq: "meaningful-messaging/cs-sani.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "meaningful-messaging/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
